/* Provide sufficient contrast against white background */
/*a {*/
/*  color: #0366d6;*/
/*}*/

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.borderNone {
  border: none !important;
  /* fix this later */
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.getInTouchContainer {
  width: 100%;
  justify-content: center;
  gap: 150px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.contact-form {
  max-width: 535px;
  min-width: 400px;
  margin-bottom: 100px;
  margin-top: 50px;
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 30px;
  border-radius: 15px;
}

.center {
  text-align: center !important;
}

@media (max-width: 767px) {
  .layout-divider {
      height: 40px;
  }
}

ul {
  list-style: none;
  padding: 0;
}


@keyframes left-side-slide {
  0% {
      transform: translateX(0px) rotate(180deg);
  }
  50% {
      transform: translateX(10px) rotate(180deg);
  }
  100% {
      transform: translateX(0px) rotate(180deg);
  }
}

@keyframes right-side-slide {
  0% {
      transform: translateX(0px);
  }
  50% {
      transform: translateX(10px);
  }
  100% {
      transform: translateX(0px);
  }
}

.modal {
  padding-top: 50px !important;
  max-height: max-content !important;
  transition: 0s !important;
}
.dropdown > .nav-link{
  padding:0 !important
}

@media (max-width: 760px) {
  
  .modal {
      padding-top: 100px !important;
  }

  .flex-row {
      flex-wrap: wrap;
      margin: 0 auto;
  }

  .contact-form {
      min-width: 360px;
  }
  .getInTouchContainer {
      gap: 50px;
  }
}

.global-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.global-scrollbar::-webkit-scrollbar-track {
  background: #f3f5f9;
  border: 1px solid #fff;
  border-radius: 15px;
  margin: 5px;
  transition: 300ms;
}

.global-scrollbar::-webkit-scrollbar-thumb {
  background: #dee3e9;
  border-radius: 10px;
  transition: 300ms;
  cursor: pointer;
}

.global-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c7cbd1;
}

.global-scrollbar::-webkit-scrollbar-corner {
  display: none;
  height: 0px;
  width: 0px;
}

.global-scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.global-scrollbar-hide::-webkit-scrollbar-track {
  display: none;
}

.global-scrollbar-hide::-webkit-scrollbar-thumb {
  display: none;
}

.modular-container{
background-color: white;
padding: 10px;
border: 2px solid #e5e5e5;
border-radius: 12px;
}

.react-confirm-alert-overlay{
z-index:1000000000  !important;
}

:root {
  --sacatelle-black-color: #101923;
  --secondary-color: #101923;
  --text-color: #101923;
  --muted-color: #999;
  --main-font-family: 'Montserrat', sans-serif;
}

body {
  font-family: var(--main-font-family);
  color: var(--text-color);
  font-size: 16px;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--secondary-color);
}

.nav-item > .dropdown-toggle::after {
  display: none !important;
}

.react-colorful {
  height: 68px !important;
  width: 200px !important;
  display: inline-block;
  margin-top: 2px;
}
