.container{
    padding: 0 !important; 
    margin-top: 56px;
    min-height: calc(100vh - 56px); 
    background-Color:rgb(243, 243, 244);
}
@media (max-width:767px) {
    .container{
        margin-top: 12px !important;
    }
}